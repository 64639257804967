import BackInfo from "../cards/bankinfo"
import FutureTask from "../cards/futureTask"
import Reason from "../cards/reason"
import "../Styles/cardHolder.css"
function Holder(){
    return(
        <div className="card-wrapper">
            <Reason></Reason>
            <FutureTask></FutureTask>
            <BackInfo></BackInfo>


        </div>
    )
}

export default Holder;