import background from "../images/me1.png"
import "../Styles/body.css"
function MainBody(){
    return(
        <div className="rapper">
          <div className="gradient"></div>
          <img src={background}></img>
          <div className="text">
            <p className="quran1">
            يَـٰٓأَيُّهَا ٱلَّذِينَ ءَامَنُوا۟ لَا تُلْهِكُمْ أَمْوَٰلُكُمْ وَلَآ أَوْلَـٰدُكُمْ عَن ذِكْرِ ٱللَّهِ
            <br></br>
                ۚ وَمَن يَفْعَلْ ذَٰلِكَ فَأُو۟لَـٰٓئِكَ هُمُ ٱلْخَـٰسِرُونَ ٩
            </p>
            <p>It Is Aboute Building Islamic Institution</p>
          </div>

    </div>
    )
}

export default MainBody;