import "../Styles/footer.css"

function Footer() {
    return (
        <div  className="footer">
            <div className="footer-wrapper">

            <div className="fo-desc">
                <h1>Logo</h1>
                <h2>Name Of The Institution</h2>
                <p>
                Description About The Institution
                </p>
            </div>

        <div className="follow">
                    <h1>Follow us</h1>
                    <p>TG</p>
                    <p>FB</p>
                    <p>Twitter</p>
        </div>
        <div className="contact">
            <h1>Keep in touch</h1>
            <div className="con-way">
            <p>Phone +251 96 948 1433</p>
            <p>Phone +251 94 628 0343</p>
            <p>Phone +251 94 286 1088</p>
            </div>

        </div>

        <div className="comments">

            <h1>Commoments</h1>
            <div>
                <form>
            <input type="text" placeholder="Name"></input>
            <br></br>
            <input type="email" placeholder="email"></input><br></br>
            <textarea id="message" placeholder="Message"></textarea>
            <button className="btn">  Submit</button>
                </form>
            </div>

        </div>

        </div>  

        </div>
    )
}

export default Footer